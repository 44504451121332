import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import { USER_INFORMATION, DEFAULT_ROUTE } from "constants/AuthConstant";

export const APP_NAME = "ThreeApples.inc";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
const defaultRoute = JSON.parse(localStorage.getItem(DEFAULT_ROUTE));
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/${defaultRoute}`;
export const UNAUTHENTICATED_ENTRY = "/login";

const currentUserTheme = JSON.parse(localStorage.getItem(USER_INFORMATION));

if (currentUserTheme?.theme_setting) {
  var userThemeData = {
    navCollapsed: currentUserTheme.theme_setting?.nav_collapsed,
    sideNavTheme: currentUserTheme?.theme_setting?.side_nav_theme,
    locale: currentUserTheme?.theme_setting?.locale,
    navType: currentUserTheme?.theme_setting?.nav_type,
    topNavColor: currentUserTheme?.theme_setting?.top_nav_color,
    headerNavColor: currentUserTheme?.theme_setting?.header_nav_color,
    mobileNav: currentUserTheme?.theme_setting?.mobile_nav,
    currentTheme: currentUserTheme?.theme_setting?.current_theme,
    direction: currentUserTheme?.theme_setting?.direction,
    blankLayout: currentUserTheme?.theme_setting?.blank_layout,
    logosLoading: false,
    logosResult: [],
  };
} else {
  var userThemeData = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: "en",
    navType: NAV_TYPE_SIDE,
    topNavColor: "#3e82f7",
    headerNavColor: "",
    mobileNav: false,
    currentTheme: "light",
    direction: DIR_LTR,
    blankLayout: false,
    logosLoading: false,
    logosResult: [],
  };
}

export const THEME_CONFIG = userThemeData;
