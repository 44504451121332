import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {CHARGES_SUMMARY, CHARGES_SUMMARY_API_URL, GET_ALL_CHARGES_SUMMARY_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ChargesSummaryErrors       : {},
  permission: permission?.charges_summary ?? [],
  ChargesSummaryShowMessage  : null,
  ChargesSummaryResult       : [],
  ChargesSummaryButtonSpinner : false,
  ChargesSummaryTableLoading : true,
  ChargesSummaryAddDrawer    : false,
  ChargesSummaryEditData     : [],
  ChargesSummaryButtonAndModelLabel  : setLocale('chargessummary.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewChargesSummaryData: [],
    ViewChargesSummaryLoader: true,
    DrawerStatus: 0,
};

export const createChargesSummary = createAsyncThunk(
    "createChargesSummary",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CHARGES_SUMMARY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getChargesSummary = createAsyncThunk(
    "getChargesSummary",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CHARGES_SUMMARY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewChargesSummary = createAsyncThunk(
  "viewChargesSummary",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CHARGES_SUMMARY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteChargesSummary = createAsyncThunk(
  "deleteChargesSummary",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CHARGES_SUMMARY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageChargesSummarySlice = createSlice({
  name: CHARGES_SUMMARY,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ChargesSummaryErrors = {};
    },
    ChargesSummaryAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.ChargesSummaryButtonSpinner = false;
      }
      state.ChargesSummaryAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ChargesSummaryEditData    = [];
      state.ChargesSummaryButtonAndModelLabel = setLocale('chargessummary.add');
    },
    ChargesSummaryEditWithDrawerStatus: (state, action) => {
      state.ChargesSummaryAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ChargesSummaryEditData = action.payload.data;
      state.ChargesSummaryButtonAndModelLabel = setLocale('chargessummary.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChargesSummary.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ChargesSummaryButtonSpinner = true;
      }).addCase(createChargesSummary.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ChargesSummaryButtonSpinner = false;
        state.ChargesSummaryErrors = {}
      }).addCase(createChargesSummary.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ChargesSummaryShowMessage = true;
        state.ChargesSummaryButtonSpinner = false;
        state.ChargesSummaryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getChargesSummary.pending, (state, action) => {
        state.ChargesSummaryButtonSpinner = true;
        state.ChargesSummaryTableLoading = true;
      }).addCase(getChargesSummary.fulfilled, (state, action) => {
        state.ChargesSummaryButtonSpinner = false;
        state.ChargesSummaryTableLoading = false;
        state.ChargesSummaryResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getChargesSummary.rejected, (state, action) => {
        state.ChargesSummaryShowMessage = true; // Set the showMessage flag to display the errors
        state.ChargesSummaryButtonSpinner = false;
        state.ChargesSummaryTableLoading = false;
        state.ChargesSummaryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewChargesSummary.pending, (state, action) => {
        state.ViewChargesSummaryLoader = true;
        state.ViewChargesSummaryData = [];
      }).addCase(viewChargesSummary.fulfilled, (state, action) => {
        state.ViewChargesSummaryLoader = false;
        state.ViewChargesSummaryData = action.payload;
      }).addCase(viewChargesSummary.rejected, (state, action) => {
        state.ViewChargesSummaryLoader = false;
        state.ViewChargesSummaryData = [];
      })
      .addCase(deleteChargesSummary.pending, (state, action) => {
        state.ChargesSummaryTableLoading = true;
      }).addCase(deleteChargesSummary.fulfilled, (state, action) => {
        state.ChargesSummaryTableLoading = false;
      }).addCase(deleteChargesSummary.rejected, (state, action) => {
        state.ChargesSummaryTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, ChargesSummaryAddDrawerStatus, ChargesSummaryEditWithDrawerStatus, updateSortFilters } = manageChargesSummarySlice.actions;

export default manageChargesSummarySlice.reducer;
